import {
  Grid,
  GridProps,
  TextField,
  IconButton,
  Button,
  InputLabel,
  FormControl,
  MenuItem,
  Select,
  Drawer,
  Switch,
  Input,
} from "@mui/material";

import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { Text, ListTable, Table, InputField } from "components/common";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import {
  useGetListings,
  useGetListing,
  useGetListingSeller,
  useGetEvents,
  useGetOffers,
  useRemoveListing,
  useSetWalkthroughUrl,
} from "hooks";
import React, { useEffect, useRef, useState } from "react";
import { useListingsStore } from "store";
import { TableData, TableDataItem, TableDataItemValue } from "types/components";
import {
  DetailedListing,
  Listing,
  ListingMoreProperties,
  Event,
  Offer,
  ListingSeller,
} from "types/entities";
import {
  INITIAL_LISTINGS_TABLE_DATA,
  INITIAL_LISTING_GENERIC_TABLE_DATA,
  INITIAL_LISTING_DETAILS_TABLE_DATA,
  INITIAL_LISTING_EVENTS_TABLE_DATA,
  INITIAL_LISTING_OFFERS_TABLE_DATA,
} from "./";
import { useEventsStore, useOffersStore } from "store";
import { getFormattedDate } from "utils";
import {
  ManageListingsUpdateForm,
  ManageListingsPhotoSelector,
  ManageListingsInspectionDocs,
} from "components/listing";
import {} from "components/listing";
import { DIMENSIONS } from "consts";
import { ViewWrapper } from "components/wrappers";
import { notification } from "antd";
import { RemoveListingFunctionParams } from "types/functions";
import { ControlPointRounded, SearchOutlined } from "@mui/icons-material";
import FilterListIcon from "@mui/icons-material/FilterList";
import SplitscreenIcon from "../../assets/images/Frame.svg";
import Rightsortingicon from "../../assets/images/Rightsortingicon.svg";
import straightIcon from "../../assets/images/straightIcon.svg";
import closeIcon from "../../assets/images/closeIcon.svg";

import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";
import { filter } from "lodash";

interface Filter {
  column: string;
  operator: string;
  value: string;
}

type Header = {
  title: string;
  key: string;
};

interface Column {
  title: string;
  key: string;
}

export const ManageListings: React.FC = () => {
  const { getListings } = useGetListings();
  const { getListing } = useGetListing();
  const { getListingSeller } = useGetListingSeller();
  const {
    loading: loadingRemoveListing,
    error: errorRemoveListing,
    removeListing,
  } = useRemoveListing();
  const { getEvents } = useGetEvents({
    configuration: { preventNetworkRequest: true },
  });
  const { getOffers } = useGetOffers();
  const { listings, listing, listingSeller } = useListingsStore();
  const { events } = useEventsStore();
  const { offers } = useOffersStore();
  const [listingsTableData, setListingsTableData] = useState<TableData>({
    ...INITIAL_LISTINGS_TABLE_DATA,
  });

  const [originalTableData, setOriginalTableData] = useState<TableData>({
    ...INITIAL_LISTINGS_TABLE_DATA,
  });

  const [availableColumn, setAvailableColumn] = useState<Column[]>(
    listingsTableData.headers
      .filter(
        (header) =>
          header.key !== "action" &&
          header.key !== "virtual_walkthrough_url" &&
          header.key !== "awaiting_approval" &&
          header.title !== ""
      )
      .map((header) => ({
        title: header.title,
        key: header.key,
      }))
  );

  const [selectedColumn, setSelectedColumn] = useState<Column[]>([]);

  const [listingDetailsTableData, setListingDetailsTableData] =
    useState<TableData>({ ...INITIAL_LISTING_DETAILS_TABLE_DATA });
  const [listingFeaturesTableData, setListingFeaturesTableData] =
    useState<TableData>({ ...INITIAL_LISTING_GENERIC_TABLE_DATA });
  const [listingConditionsTableData, setListingConditionsTableData] =
    useState<TableData>({ ...INITIAL_LISTING_GENERIC_TABLE_DATA });
  const [listingFloodInfoTableData, setListingFloodInfoTableData] =
    useState<TableData>({ ...INITIAL_LISTING_GENERIC_TABLE_DATA });
  const [listingMoreInfoTableData, setListingMoreInfoTableData] =
    useState<TableData>({ ...INITIAL_LISTING_GENERIC_TABLE_DATA });
  const [listingDefectsTableData, setListingDefectsTableData] =
    useState<TableData>({ ...INITIAL_LISTING_GENERIC_TABLE_DATA });
  const [listingExclusionsTableData, setListingExclusionsTableData] =
    useState<TableData>({ ...INITIAL_LISTING_GENERIC_TABLE_DATA });
  const [listingSellerTableData, setListingSellerTableData] =
    useState<TableData>({ ...INITIAL_LISTING_GENERIC_TABLE_DATA });
  const [listingEventsTableData, setListingEventsTableData] =
    useState<TableData>({ ...INITIAL_LISTING_EVENTS_TABLE_DATA });
  const [listingOffersTableData, setListingOffersTableData] =
    useState<TableData>({ ...INITIAL_LISTING_OFFERS_TABLE_DATA });
  const [api, contextHolder] = notification.useNotification();
  const {
    loading: loadingWalkthroughUrl,
    error: errorWalkthroughUrl,
    setWalkthroughUrl,
  } = useSetWalkthroughUrl();
  const [virtualWalkthroughUrl, setVirtualWalkthroughUrl] =
    useState<string>("");

  const [selectedListing, setSelectedListing] = useState<string>("");

  const [keyword, setKeyword] = useState<string>("");

  const [check, setCheck] = useState<boolean>(false);
  const [pendingCheck, setPendingCheck] = useState(false);
  const [apiCalled, setApiCalled] = useState<boolean>(false);

  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const handleRowClick = (
    item: TableDataItem,

    setStateFunc: React.Dispatch<React.SetStateAction<string>>,
    setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>
  ) => {
    setStateFunc(item.id as string);
    setVirtualWalkthroughUrl(
      (item.virtual_walkthrough_url as string | null) || ""
    );
    setOpenDrawer(true); // Set openDrawer to true
  };
  const [open, setOpen] = React.useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const filterButtonRef = useRef(null);
  const [position, setPosition] = React.useState({ top: 0, left: 0 });

  const handleSelectColumn = (column: any) => {
    setAvailableColumn((prev) =>
      prev.filter((item) => item.key !== column.key)
    );
    setSelectedColumn((prev) => [...prev, column]);
  };

  const handleDeselectColumn = (column: any) => {
    setSelectedColumn((prev) => prev.filter((item) => item.key !== column.key));
    setAvailableColumn((prev) => [...prev, column]);
  };

  const handleSave = () => {
    const selectedKeys = selectedColumn.map((column) => column.key);

    // Filter data based on selected keys
    const updatedData = originalTableData.data.map((row) =>
      Object.keys(row)
        .filter(
          (key) =>
            key === "id" || key === "action" || selectedKeys.includes(key)
        )
        .reduce((filteredRow, key) => {
          filteredRow[key] = row[key];
          return filteredRow;
        }, {} as Record<string, any>)
    );

    setListingsTableData((prevData) => ({
      ...prevData,
      headers: [
        ...selectedColumn,
        { title: "Action", key: "action" }, // Ensure "action" is part of the headers
      ],
      data: updatedData,
    }));
  };

  const handleOpen = () => {
    if (filterButtonRef.current) {
      const rect = filterButtonRef;
      // @ts-ignore
      const top = rect.current["offsetTop"] + 160;
      // @ts-ignore
      const left = rect.current["offsetLeft"] + 450;
      setPosition({
        top: top, // Adjust for vertical scrolling
        left: left, // Adjust for horizontal scrolling
      });
    }
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  //Filters
  const [filters, setFilters] = useState<Filter[]>([
    { column: "", operator: "", value: "" },
  ]);
  const handleAddFilter = () => {
    setFilters([...filters, { column: "", operator: "", value: "" }]);
  };

  // console.log(filters, "filters");

  // Remove a specific filter row
  const handleRemoveFilter = (index: number) => {
    setFilters(filters.filter((_, i) => i !== index));
  };

  // Clear all filters
  const handleCleanAll = () => {
    setFilters([{ column: "", operator: "", value: "" }]);
  };

  // Update a specific filter
  const handleUpdateFilter = (
    index: number,
    field: keyof Filter,
    value: string
  ) => {
    const updatedFilters = [...filters];
    updatedFilters[index][field] = value;
    setFilters(updatedFilters);
  };

  //Drawer

  const toggleDrawer = (newOpen: any) => () => {
    setOpenDrawer(newOpen);
  };

  //Tabs
  const [value, setValue] = useState("Information");

  const handleChangeTabs = (event: any, newValue: any) => {
    setValue(newValue);
  };

  /**
   * Maps data while adhering their key->title mapping set in their INITIAL_*_TABLE_DATA const var
   */
  const mapTranslatedDataToTableData = (
    data: Listing[],
    initialTableData: TableData,
    setStateFunc: React.Dispatch<React.SetStateAction<TableData>>
  ) => {
    const newTableData: TableData = { ...initialTableData, data: [] };

    data.forEach((item) => {
      const tableItem: TableDataItem = {};
      // Make sure every tableItem contains 'id' field
      tableItem.id = item.id;

      newTableData.headers.forEach((header) => {
        const key = header.key as keyof Listing;
        // @ts-ignore
        tableItem[key] = item[key] as TableDataItemValue;
      });
      newTableData.data.push(tableItem);
    });
    setStateFunc({ ...newTableData });
  };

  const mapEventsToTableData = (
    data: Event[],
    initialTableData: TableData,
    setStateFunc: React.Dispatch<React.SetStateAction<TableData>>
  ) => {
    const newTableData: TableData = { ...initialTableData, data: [] };
    data.forEach((item) => {
      const tableItem: TableDataItem = {};
      // Make sure every tableItem contains 'id' field
      tableItem.id = item.id;

      newTableData.headers.forEach((header) => {
        const key = header.key as keyof Event;

        // Handle cases requiring data translation
        if (key === "inviter" || key === "invitee") {
          if (Object.keys(item[key]).length === 0) {
            tableItem[key] = "-";
          } else {
            tableItem[key] = `${item[key].given_name} ${item[key].family_name}`;
          }
          return;
        }
        if (key === "minutes_start" || key === "minutes_end") {
          tableItem[key] =
            Math.floor(item[key] / 60)
              .toString()
              .padStart(2, "0") +
            ":" +
            Math.floor(item[key] % 60)
              .toString()
              .padStart(2, "0") +
            " UTC";
          return;
        }
        if (key === "date_specific") {
          const date = new Date(item[key]);
          tableItem[key] = getFormattedDate(date);
          return;
        }
        tableItem[key] = item[key] as TableDataItemValue;
      });
      newTableData.data.push(tableItem);
    });
    setStateFunc({ ...newTableData });
  };

  const mapOffersToTableData = (
    data: Offer[],
    initialTableData: TableData,
    setStateFunc: React.Dispatch<React.SetStateAction<TableData>>
  ) => {
    const newTableData: TableData = { ...initialTableData, data: [] };
    data.forEach((item) => {
      const tableItem: TableDataItem = {};
      // Make sure every tableItem contains 'id' field
      tableItem.id = item.id;

      newTableData.headers.forEach((header) => {
        const key = header.key as keyof Offer;

        // Handle cases requiring data translation
        if (key === "user") {
          tableItem[key] = `${item[key].given_name} ${item[key].family_name}`;
          return;
        }
        if (key === "closing_date" || key === "last_update_date") {
          const date = new Date(item[key]);
          tableItem[key] = getFormattedDate(date);
          return;
        }

        tableItem[key] = item[key] as TableDataItemValue;
      });
      newTableData.data.push(tableItem);
    });
    setStateFunc({ ...newTableData });
  };

  const mapListingDetailsToTableData = (listing: DetailedListing) => {
    const newTableData: TableData = {
      ...INITIAL_LISTING_DETAILS_TABLE_DATA,
      data: [],
    };
    const data: TableDataItem = {};

    newTableData.headers.forEach((header) => {
      const key = header.key as keyof DetailedListing;
      data[key] = listing[key] as TableDataItemValue;
    });
    newTableData.data = [data];

    setListingDetailsTableData({ ...newTableData });
  };

  /**
   * Maps data while disregarding their key->title mapping set in their INITIAL_*_TABLE_DATA const var
   */
  const mapNonTranslatedDetailsToListTableData = (
    data: ListingMoreProperties[keyof ListingMoreProperties] | ListingSeller,
    setStateFunc: React.Dispatch<React.SetStateAction<TableData>>
  ) => {
    const keys = Object.keys(data);
    const newTableData: TableData = {
      headers: keys.map((key) => ({ key, title: key })),
      data: [],
    };
    newTableData.data = [data as TableDataItem];

    setStateFunc({ ...newTableData });
  };

  const fetchListingDetails = (selectedListing: string) => {
    const listing = listings.find((listing) => listing.id === selectedListing);
    if (listing) {
      getListing({ listingId: selectedListing });
      getOffers({ listingId: selectedListing });
    } else alert(`Can't find a listing with id: ${selectedListing}`);
  };

  const handleConfirm = () => {
    setWalkthroughUrl({
      url: virtualWalkthroughUrl,
      listingId: selectedListing,
    }).then(() => getListings());
    api.success({
      message: `Successfully changed url`,
      placement: "bottomRight",
    });
    setOpenDrawer(false);
  };

  const handleViewWalkthrough = () => {
    window.open(virtualWalkthroughUrl, "_blank")?.focus();
  };

  useEffect(() => {
    mapTranslatedDataToTableData(
      listings,
      INITIAL_LISTINGS_TABLE_DATA,
      setListingsTableData
    );
  }, [listings]);

  useEffect(() => {
    if (!selectedListing) return;
    fetchListingDetails(selectedListing);
  }, [selectedListing]);

  useEffect(() => {
    // Check if listing is empty
    if (!listing || JSON.stringify(listing) === JSON.stringify({})) return;

    // Typescript doesn't seem to care that we've just checked whether listing object
    // is empty. Unfortunately we need to spam type assertion.
    mapListingDetailsToTableData(listing as DetailedListing);
    mapNonTranslatedDetailsToListTableData(
      (listing as DetailedListing)?.features,
      setListingFeaturesTableData
    );
    mapNonTranslatedDetailsToListTableData(
      (listing as DetailedListing)?.conditions,
      setListingConditionsTableData
    );
    mapNonTranslatedDetailsToListTableData(
      (listing as DetailedListing)?.defects,
      setListingDefectsTableData
    );
    mapNonTranslatedDetailsToListTableData(
      (listing as DetailedListing)?.flood_info,
      setListingFloodInfoTableData
    );
    mapNonTranslatedDetailsToListTableData(
      (listing as DetailedListing)?.more_info,
      setListingMoreInfoTableData
    );
    mapNonTranslatedDetailsToListTableData(
      (listing as DetailedListing)?.exclusions,
      setListingExclusionsTableData
    );
    getListingSeller({ listing: listing as DetailedListing });
    getEvents({ listingId: (listing as DetailedListing).id });
  }, [listing]);

  const appendNewListingsToTableData = (
    newListings: Listing[],
    initialTableData: TableData,
    setStateFunc: React.Dispatch<React.SetStateAction<TableData>>
  ) => {
    const updatedTableData: TableData = { ...initialTableData };

    newListings.forEach((item) => {
      const tableItem: TableDataItem = {};
      // Ensure every tableItem contains 'id' field
      tableItem.id = item.id;

      updatedTableData.headers.forEach((header) => {
        const key = header.key as keyof Listing;
        // @ts-ignore
        tableItem[key] = item[key] as TableDataItemValue;
      });
      updatedTableData.data.push(tableItem);
    });

    setStateFunc({ ...updatedTableData });
  };

  useEffect(() => {
    // Check if listingSeller is empty
    if (!listingSeller || JSON.stringify(listingSeller) === JSON.stringify({}))
      return;

    mapNonTranslatedDetailsToListTableData(
      listingSeller as ListingSeller,
      setListingSellerTableData
    );
  }, [listingSeller]);

  useEffect(() => {
    mapEventsToTableData(
      events,
      INITIAL_LISTING_EVENTS_TABLE_DATA,
      setListingEventsTableData
    );
  }, [events]);

  useEffect(() => {
    mapOffersToTableData(
      offers,
      INITIAL_LISTING_OFFERS_TABLE_DATA,
      setListingOffersTableData
    );
  }, [offers]);

  useEffect(() => {
    const fetchListings = async () => {
      setIsLoading(true);
      try {
        if (filters[0]?.column?.trim() == "") {
          let newListings;
          if (pendingCheck) {
            // getListings({ status: "Pending" });
            newListings = await getListings({
              page,
              keyword,
              status: "Pending",
            });
          } else {
            newListings = await getListings({ page, keyword });
          }

          if (!apiCalled) {
            appendNewListingsToTableData(
              newListings,
              listingsTableData,
              setListingsTableData
            );
          } else {
            if (pendingCheck) {
              // getListings({ status: "Pending" });
              getListings({ page, keyword, status: "Pending" });
            } else {
              getListings({ page, keyword });
            }

            // getListings({ page, keyword });
            setApiCalled(false);
          }
        } else {
          let newListings;
          if (pendingCheck) {
            // getListings({ status: "Pending" });
            newListings = await getListings({
              filters,
              page,
              keyword,
              status: "Pending",
            });
          } else {
            newListings = await getListings({ filters, page, keyword });
          }
          if (page != 1) {
            appendNewListingsToTableData(
              newListings,
              listingsTableData,
              setListingsTableData
            );
          }
        }
      } catch (error) {
        console.error("Error fetching listings:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchListings();
  }, [page]);

  useEffect(() => {
    setApiCalled(true);
    const delayDebounce = setTimeout(() => {
      if (filters[0]?.column?.trim() == "") {
        if (!keyword || keyword?.trim() === "") {
          if (page == 1) {
            getListings({ keyword, page: 1 });
          } else {
            setPage(1);
          }
        } else {
          if (page == 1) {
            getListings({ keyword, page: 1 });
          } else {
            setPage(1);
          }

          // console.log("API called with keyword:", keyword);
        }
      } else {
        if (!keyword || keyword?.trim() === "") {
          if (page == 1) {
            setPage(1);
            getListings({ filters, keyword, page: 1 });
          } else {
            setPage(1);
          }
        } else {
          if (page == 1) {
            setPage(1);
            getListings({ filters, keyword, page: 1 });
          } else {
            setPage(1);
          }

          // console.log("API called with keyword:", keyword);
        }
      }
    }, 500);

    return () => clearTimeout(delayDebounce);
  }, [keyword, 500]);

  // useEffect(() => {
  //   const delayDebounce = setTimeout(() => {
  //     getListings({ keyword });
  //     // console.log("API called with keyword:", keyword);
  //   }, 500);

  //   return () => clearTimeout(delayDebounce);
  // }, [keyword]);

  useEffect(() => {
    if (check) {
      setPage(1);
      getListings();
      setCheck(false);
    }
  }, [check]);

  useEffect(() => {
    if (!open) {
      setPage(1);
      getListings({ filters });
      setKeyword("");
    }
  }, [open]);

  useEffect(() => {
    // console.log(filters, "====");
    setApiCalled(true);
    const fetchListings = async () => {
      setIsLoading(true);
      try {
        if (filters[0]?.column?.trim() == "") {
          let newListings;
          if (pendingCheck) {
            // getListings({ status: "Pending" });
            newListings = await getListings({
              page,
              keyword,
              status: "Pending",
            });
          } else {
            newListings = await getListings({ page, keyword });
          }
          // console.log(apiCalled,"===")
          if (false) {
            appendNewListingsToTableData(
              newListings,
              listingsTableData,
              setListingsTableData
            );
          } else {
            if (pendingCheck) {
              // getListings({ status: "Pending" });
              getListings({ page, keyword, status: "Pending" });
            } else {
              getListings({ page, keyword });
            }

            // getListings({ page, keyword });
            setApiCalled(false);
          }
        } else {
          let newListings;
          if (pendingCheck) {
            // getListings({ status: "Pending" });
            newListings = await getListings({
              filters,
              page,
              keyword,
              status: "Pending",
            });
          } else {
            newListings = await getListings({ filters, page, keyword });
          }
          if (false) {
            appendNewListingsToTableData(
              newListings,
              listingsTableData,
              setListingsTableData
            );
          }
        }
      } catch (error) {
        console.error("Error fetching listings:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (!pendingCheck) {
      // console.log("====")
      setPage(1);
      fetchListings();
      return;
    } else {
      setPage(1);
      fetchListings();
    }

    fetchListings();
  }, [pendingCheck]);

  const handleLoadMore = () => {
    // if(pendingCheck && page==1){
    //   setPage(0)
    //   setPage(1)

    // }
    // setPage((prevPage) => prevPage + 1);
    // setApiCalled(false);
    setPage((prevPage) => prevPage + 1);
    setApiCalled(false);
  };

  useEffect(() => {
    if (!loadingRemoveListing && !errorRemoveListing) {
      setSelectedListing("");
    }
  }, [loadingRemoveListing, errorRemoveListing]);
  interface GridItemProps extends GridProps {
    children: React.ReactNode;
  }
  const GridItem: React.FC<GridItemProps> = ({ children, ...props }) => (
    <Grid
      item
      xs={3}
      maxHeight={DIMENSIONS.MAX_GRID_ITEM_HEIGHT}
      overflow="auto"
      {...props}
    >
      {children}
    </Grid>
  );

  const style = {
    position: "absolute",
    top: "31.5%",
    left: "49.5%",
    transform: "translate(-50%, -30%)",
    width: 560,
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 3,
    p: 4,
  };

  //   // if (!selectedRowId) {
  //   //   console.error("No selectedRowId found");
  //   //   return;
  //   // }
  //   // console.log(selectedRowId, "selectedRowIdss");
  //   // removeListing({ listingId: selectedRowId }).then(() => {
  //   //   getListings(); // Refresh the listings after removal
  //   //   api.success({
  //   //     message: `Listing with ID ${selectedRowId} removed successfully`,
  //   //     placement: "bottomRight",
  //   //   });
  //   // });
  // };

  const [openChooseColumn, setOpenChooseColumn] = useState(false);
  const handleOpenChooseColumn = () => setOpenChooseColumn(true);
  const handleCloseChooseColumn = () => setOpenChooseColumn(false);
  return (
    <ViewWrapper title="Manage Listings">
      {contextHolder}

      <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
        <TextField
          id="standard-bare"
          // style={{borderRadius:"30px"}}
          defaultValue="Search"
          placeholder="Search"
          InputProps={{
            startAdornment: (
              <IconButton>
                <SearchOutlined />
              </IconButton>
            ),
            style: {
              borderRadius: "30px",
              height: "40px",
              width: "400px",
              fontFamily: "PlusJakartaSans-Regular",
            },
          }}
          value={keyword}
          onChange={(e) => setKeyword(e.target.value)}
        />

        <Button
          variant="outlined"
          style={{
            backgroundColor: "black",
            textTransform: "none",
            borderRadius: "30px",
            color: "white",
            border: "1px solid #DDDDDD",
            fontFamily: "PlusJakartaSans-Regular",
          }}
          startIcon={<FilterListIcon />}
          onClick={handleOpen}
          ref={filterButtonRef}
        >
          Filter
        </Button>
      </div>

      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        BackdropProps={{
          style: {
            backgroundColor: "transparent",
            backdropFilter: "blur(2px)",
          }, // Removes opacity
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: `${position?.top}px`,
            left: `${position?.left}px`,
            transform: "translate(-50%, -50%)",
            width: 900,
            bgcolor: "background.paper",
            boxShadow: 24,
            borderRadius: 3,
            p: 2,
          }}
        >
          <Box
            display="flex"
            justifyContent="start"
            alignItems="center"
            marginTop="20px"
          >
            <p
              style={{
                margin: 0,
                paddingBottom: "30px",
                fontFamily: "PlusJakartaSans-SemiBold",
              }}
            >
              Filter
            </p>
          </Box>
          <Box sx={{ justifyContent: "center" }}>
            <Box sx={{ maxHeight: "350px", overflowX: "auto" }}>
              {filters.map((filter, index) => (
                <>
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      mb: 2,
                      gap: "10px",
                      mt: 4,
                    }}
                  >
                    {/* <FormControl>
                      <InputLabel
                        htmlFor="first-name"
                        sx={{
                          color: "black",
                          fontFamily: "PlusJakartaSans-SemiBold",
                          marginTop: "-28px",
                        }}
                      >
                        Column
                      </InputLabel>
                      <Box sx={{ marginBottom: "15px" }} />
                      <TextField
                        id={`column-${index}`}
                        value={filter.column}
                        onChange={(e) =>
                          handleUpdateFilter(index, "column", e.target.value)
                        }
                        InputProps={{
                          endAdornment: (
                            <IconButton>
                              <ArrowDropDownIcon sx={{ color: "black" }} />
                            </IconButton>
                          ),
                          style: {
                            borderRadius: "30px",
                            height: "35px",
                            width: "100%",
                            fontSize: "15px",
                            fontFamily: "PlusJakartaSans-Regular",
                          },
                        }}
                        sx={{ marginLeft: "10px", marginRight: "10px" }}
                      />
                    </FormControl> */}
                    {filters?.length > 1 && (
                      <img
                        src={closeIcon}
                        alt="Close Icon"
                        style={{ cursor: "pointer", marginTop: "3px" }}
                        onClick={() => handleRemoveFilter(index)}
                      />
                    )}

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                        width: "30%",
                      }}
                    >
                      <InputLabel
                        htmlFor="first-name"
                        sx={{
                          color: "black",
                          fontFamily: "PlusJakartaSans-SemiBold",
                          marginTop: "-28px",
                        }}
                      >
                        Column
                      </InputLabel>
                      <FormControl>
                        <Select
                          id={`column-${index}`}
                          value={filter.column}
                          onChange={(e) =>
                            handleUpdateFilter(index, "column", e.target.value)
                          }
                          sx={{
                            borderRadius: "30px",
                            height: "35px",
                            width: "100%",
                            fontSize: "15px",
                            fontFamily: "PlusJakartaSans-Regular",
                          }}
                          IconComponent={() => (
                            <ArrowDropDownIcon sx={{ color: "black" }} />
                          )}
                        >
                          {listingsTableData?.headers
                            ?.filter((x) => x.title !== "Status")
                            ?.map((h) => (
                              <MenuItem key={h.key} value={h.key}>
                                {h.title}
                              </MenuItem>
                            ))}
                        </Select>
                      </FormControl>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                        width: "30%",
                      }}
                    >
                      <InputLabel
                        id={`operator-label-${index}`}
                        sx={{
                          color: "black",
                          fontFamily: "PlusJakartaSans-SemiBold",
                          marginTop: "-28px",
                        }}
                      >
                        Operator
                      </InputLabel>
                      <FormControl>
                        <Select
                          labelId={`operator-label-${index}`}
                          id={`operator-${index}`}
                          value={filter.operator}
                          onChange={(e) =>
                            handleUpdateFilter(
                              index,
                              "operator",
                              e.target.value
                            )
                          }
                          sx={{
                            borderRadius: "30px",
                            height: "35px",
                            width: "100%",
                            fontSize: "15px",
                            fontFamily: "PlusJakartaSans-Regular",
                          }}
                          IconComponent={() => (
                            <ArrowDropDownIcon sx={{ color: "black" }} />
                          )}
                        >
                          <MenuItem value="equals">=</MenuItem>
                          <MenuItem value="not equals">!=</MenuItem>
                          <MenuItem value="contain">Contain</MenuItem>
                          <MenuItem value="not contain"> Not Contain</MenuItem>
                          <MenuItem value="start with">Starts With</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>

                    {/* <FormControl>
                      <InputLabel
                        htmlFor="first-name"
                        sx={{
                          color: "black",
                          fontFamily: "PlusJakartaSans-SemiBold",
                          marginTop: "-28px",
                        }}
                      >
                        Operator
                      </InputLabel>
                      <Box sx={{ marginBottom: "15px" }} />
                      <TextField
                        id={`operator-${index}`}
                        value={filter.operator}
                        onChange={(e) =>
                          handleUpdateFilter(index, "operator", e.target.value)
                        }
                        InputProps={{
                          endAdornment: (
                            <IconButton>
                              <ArrowDropDownIcon sx={{ color: "black" }} />
                            </IconButton>
                          ),
                          style: {
                            borderRadius: "30px",
                            height: "35px",
                            width: "100%",
                            fontSize: "15px",
                            fontFamily: "PlusJakartaSans-Regular",
                          },
                        }}
                        sx={{ marginLeft: "10px", marginRight: "10px" }}
                      />{" "}
                    </FormControl> */}
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "10px",
                        width: "30%",
                      }}
                    >
                      <InputLabel
                        htmlFor="first-name"
                        sx={{
                          color: "black",
                          fontFamily: "PlusJakartaSans-SemiBold",
                          marginTop: "-28px",
                        }}
                      >
                        Value
                      </InputLabel>
                      <FormControl>
                        <TextField
                          id={`value-${index}`}
                          value={filter.value}
                          onChange={(e) =>
                            handleUpdateFilter(index, "value", e.target.value)
                          }
                          InputProps={{
                            style: {
                              borderRadius: "30px",
                              height: "35px",
                              fontSize: "15px",
                              fontFamily: "PlusJakartaSans-Regular",
                            },
                          }}
                          sx={{ marginRight: "10px" }}
                        />
                      </FormControl>
                    </Box>
                  </Box>
                </>
              ))}
            </Box>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              marginTop="20px"
            >
              <Button
                variant="outlined"
                style={{
                  backgroundColor: "#FFE500",
                  textTransform: "none",
                  borderRadius: "30px",
                  color: "black",
                  border: "1px solid #DDDDDD",
                  fontSize: "15px",
                  fontFamily: "PlusJakartaSans-Regular",
                }}
                startIcon={<ControlPointIcon />}
                onClick={handleAddFilter}
              >
                Add Filter
              </Button>

              <p
                style={{
                  cursor: "pointer",
                  margin: 0,
                  textDecoration: "underline",
                  fontSize: "15px",
                  color: "black",
                  fontFamily: "PlusJakartaSans-Regular",
                }}
                onClick={handleCleanAll}
              >
                Clean All
              </p>
            </Box>
          </Box>
        </Box>
      </Modal>

      <Box display="flex" alignItems="center">
        <Text marginTop="20px" sx={{ marginRight: "auto", marginTop: "20px" }}>
          {listingsTableData?.data?.length} Results
        </Text>

        <Box display="flex" alignItems="center" sx={{ marginRight: 2 }}>
          <Text variant="body2" sx={{ marginRight: 1 }}>
            Pending listings
          </Text>
          <Switch
            checked={pendingCheck}
            onChange={(event) => setPendingCheck(event.target.checked)}
            color="primary"
            sx={{
              "& .MuiSwitch-switchBase.Mui-checked": {
                color: "#FFE500", // Customize the color when checked
              },
              "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
                backgroundColor: "#000000", // Customize the track color when checked
                opacity: 1,
              },
            }}
          />
        </Box>

        <Button
          sx={{
            backgroundColor: "white",
            borderRadius: "30px",
            textTransform: "none",
            color: "black",
            border: "1px solid #DDDDDD",
            width: "180px",
            fontFamily: "PlusJakartaSans-Regular",
          }}
          startIcon={<img src={SplitscreenIcon} />}
          onClick={handleOpenChooseColumn}
        >
          Choose Columns
        </Button>
      </Box>
      {/* <GridItem> */}
      <Box>
        <Table
          tableData={listingsTableData}
          onRowClick={(item) =>
            handleRowClick(item, setSelectedListing, setOpenDrawer)
          }
          selectedRowId={selectedListing}
          setCheck={setCheck}
          setPendingCheck={setPendingCheck}
          check={check}
        />
        {isLoading ? (
          <p>Loading...</p>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "10px",
            }}
          >
            {/* <button
              onClick={handleLoadMore}
              style={{
                backgroundColor: "black",
                border: "none",
                color: "white",
              }}
            >
              Load More
            </button> */}

            <Button
              variant="outlined"
              style={{
                backgroundColor: "black",
                textTransform: "none",
                borderRadius: "30px",
                color: "white",
                border: "1px solid #DDDDDD",
                fontFamily: "PlusJakartaSans-Regular",
              }}
              startIcon={<KeyboardArrowDownIcon />}
              onClick={handleLoadMore}
            >
              Load More
            </Button>
          </div>
        )}
      </Box>
      {/* </GridItem> */}

      {/* <Button onClick={toggleDrawer(true)}>Open drawer</Button> */}
      <Drawer
        slotProps={{
          backdrop: {
            style: {
              backgroundColor: "#11111180 ",
              backdropFilter: "blur(2px)",
              // zIndex: "99999",
            },
          },
        }}
        PaperProps={{
          sx: {
            borderRadius: "16px",
            height: "97.5vh",
            margin: "10px",
            px: "40px",
            py: "40px",
            overflow: "auto",
            scrollbarWidth: "thin",
            "&::-webkit-scrollbar": {
              width: "6px",
              height: "6px",
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "#f1f1f1",
              borderRadius: "16px",
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "#4caf50",
              borderRadius: "16px",
              border: "none",
            },

            // overflowY: 'auto',

            width: "40%",
          },
        }}
        anchor="right"
        open={openDrawer}
        onClose={toggleDrawer(false)}
      >
        <Box sx={{ width: "100%", typography: "body1" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "20px",
            }}
          >
            <Text
              sx={{
                color: "black",
                fontFamily: "PlusJakartaSans-Regular",
                fontWeight: "600",
                fontSize: "24px",
              }}
            >
              32 Texas Avenue
            </Text>
            <img
              src={closeIcon}
              alt="Close Icon"
              style={{ cursor: "pointer" }}
              onClick={toggleDrawer(false)}
            />
          </Box>
          <TabContext value={value}>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: "divider",
              }}
            >
              <TabList
                sx={{
                  "& .MuiTabs-indicator": { backgroundColor: "black" }, // Style for the underline
                  "& .MuiTab-root": {
                    color: "#000000B2",
                    fontFamily: "PlusJakartaSans-Regular",
                    textTransform: "none",
                    mr: "-14px",
                  }, // Default tab text color
                  "& .MuiTab-root.Mui-selected": {
                    color: "black",
                    fontFamily: "PlusJakartaSans-Bold",
                    textTransform: "none",
                    mr: "-14px",
                  }, // Active tab text color
                }}
                onChange={handleChangeTabs}
                aria-label="lab API tabs example"
              >
                <Tab label="Information" value="Information" />
                <Tab label="Details" value="Details" />
                <Tab label="Events & Offers" value="Events & Offers" />
                <Tab label="Documents" value="Documents" />
                <Tab label="Photos" value="Photos" />
                <Tab label="Walkthrough" value="Walkthrough" />
              </TabList>
            </Box>
            <TabPanel
              value="Information"
              style={{
                padding: "0px",
                margin: "0px",
                paddingTop: "20px",
              }}
            >
              {" "}
              <ManageListingsUpdateForm
                listing={listing as DetailedListing}
                removeListing={async (params: RemoveListingFunctionParams) => {
                  const res = await removeListing(params);
                  api.success({
                    message: `Listing removed`,
                    placement: "bottomRight",
                  });
                  return res;
                }}
                loadingRemoveListing={loadingRemoveListing}
                errorRemoveListing={!!errorRemoveListing}
                setCheck={setCheck}
                setOpenDrawer={setOpenDrawer}
                setApiCalled={setApiCalled}
                setPendingCheck={setPendingCheck}
                setFilters={setFilters}
                setKeyword={setKeyword}
              />
            </TabPanel>

            <TabPanel
              value="Details"
              style={{
                padding: "0px",
                margin: "0px",
                paddingTop: "20px",
              }}
            >
              <Text
                sx={{
                  fontFamily: "PlusJakartaSans-Regular",
                  fontSize: "14px",
                  fontWeight: "600",
                }}
                marginBottom="8px"
              >
                Details
              </Text>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  // p: 1,
                  // m: 1,
                  bgcolor: "background.paper",
                  borderRadius: 1,
                  width: "100%",
                }}
              >
                <ListTable
                  tableData={listingDetailsTableData}
                  showSplit={false}
                />
              </Box>
              <div
                style={{
                  margin: "30px 0px 30px 0px",
                  height: "2px",
                  width: "100%",
                  backgroundColor: "#E9E9E9",
                }}
              ></div>
              <Text
                sx={{
                  fontFamily: "PlusJakartaSans-Regular",
                  fontSize: "14px",
                  fontWeight: "600",
                }}
                marginBottom="8px"
              >
                Features
              </Text>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  // p: 1,
                  // m: 1,
                  bgcolor: "background.paper",
                  borderRadius: 1,
                  width: "100%",
                }}
                marginTop="20px"
              >
                <ListTable
                  tableData={listingFeaturesTableData}
                  showSplit={true}
                />
              </Box>
              <div
                style={{
                  margin: "30px 0px 30px 0px",
                  height: "2px",
                  width: "100%",
                  backgroundColor: "#E9E9E9",
                }}
              ></div>
              <Text
                sx={{
                  fontFamily: "PlusJakartaSans-Regular",
                  fontSize: "14px",
                  fontWeight: "600",
                }}
                marginBottom="8px"
              >
                Conditions
              </Text>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  // p: 1,
                  // m: 1,
                  bgcolor: "background.paper",
                  borderRadius: 1,
                  width: "100%",
                }}
                marginTop="20px"
              >
                <ListTable
                  tableData={listingConditionsTableData}
                  showSplit={false}
                />
              </Box>
              <div
                style={{
                  margin: "30px 0px 30px 0px",
                  height: "2px",
                  width: "100%",
                  backgroundColor: "#E9E9E9",
                }}
              ></div>
              <Text
                sx={{
                  fontFamily: "PlusJakartaSans-Regular",
                  fontSize: "14px",
                  fontWeight: "600",
                }}
                marginBottom="8px"
              >
                Flood Info
              </Text>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  // p: 1,
                  // m: 1,
                  bgcolor: "background.paper",
                  borderRadius: 1,
                  width: "100%",
                }}
              >
                <ListTable
                  tableData={listingFloodInfoTableData}
                  showSplit={false}
                />
              </Box>
              <div
                style={{
                  margin: "30px 0px 30px 0px",
                  height: "2px",
                  width: "100%",
                  backgroundColor: "#E9E9E9",
                }}
              ></div>
              <Text
                sx={{
                  fontFamily: "PlusJakartaSans-Regular",
                  fontSize: "14px",
                  fontWeight: "600",
                }}
                marginBottom="8px"
              >
                More Info
              </Text>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  // p: 1,
                  // m: 1,
                  bgcolor: "background.paper",
                  borderRadius: 1,
                  width: "100%",
                }}
                marginTop="20px"
              >
                <ListTable
                  tableData={listingMoreInfoTableData}
                  showSplit={false}
                />
              </Box>
              <div
                style={{
                  margin: "30px 0px 30px 0px",
                  height: "2px",
                  width: "100%",
                  backgroundColor: "#E9E9E9",
                }}
              ></div>
              <Text
                sx={{
                  fontFamily: "PlusJakartaSans-Regular",
                  fontSize: "14px",
                  fontWeight: "600",
                }}
                marginBottom="8px"
              >
                Defects
              </Text>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  // p: 1,
                  // m: 1,
                  bgcolor: "background.paper",
                  borderRadius: 1,
                  width: "100%",
                }}
                marginTop="20px"
              >
                <ListTable
                  tableData={listingDefectsTableData}
                  showSplit={true}
                />
              </Box>
              <div
                style={{
                  margin: "30px 0px 30px 0px",
                  height: "2px",
                  width: "100%",
                  backgroundColor: "#E9E9E9",
                }}
              ></div>
              <Text
                sx={{
                  fontFamily: "PlusJakartaSans-Regular",
                  fontSize: "14px",
                  fontWeight: "600",
                }}
                marginBottom="8px"
              >
                Exclusions
              </Text>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  // p: 1,
                  // m: 1,
                  bgcolor: "background.paper",
                  borderRadius: 1,
                  width: "100%",
                }}
                marginTop="20px"
              >
                <ListTable
                  tableData={listingExclusionsTableData}
                  showSplit={false}
                />
              </Box>
              <div
                style={{
                  margin: "30px 0px 30px 0px",
                  height: "2px",
                  width: "100%",
                  backgroundColor: "#E9E9E9",
                }}
              ></div>
              <Text
                sx={{
                  fontFamily: "PlusJakartaSans-Regular",
                  fontSize: "14px",
                  fontWeight: "600",
                }}
                marginBottom="8px"
              >
                Seller Info
              </Text>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  // p: 1,
                  // m: 1,
                  bgcolor: "background.paper",
                  borderRadius: 1,
                  width: "100%",
                }}
                marginTop="20px"
              >
                <ListTable
                  tableData={listingSellerTableData}
                  showSplit={false}
                />
              </Box>
              {/* <Grid container spacing={2} alignItems="flex-start">
                <GridItem>
                  <Text variant="h60} marginBottom="20px" sx={{fontFamily: "PlusJakartaSansBoldr",}}>
                    Details
                  </Text>
                  <ListTable tableData={listingDetailsTableData} />
                </GridItem>
                <GridItem>
                  <Text variant="h60} marginBottom="20px" sx={{fontFamily: "PlusJakartaSansBoldr",}}>
                    Features
                  </Text>
                  <ListTable tableData={listingFeaturesTableData} />
                </GridItem>
                <GridItem>
                  <Text variant="h60} marginBottom="20px" sx={{fontFamily: "PlusJakartaSansBoldr",}}>
                    Conditions
                  </Text>
                  <ListTable tableData={listingConditionsTableData} />
                </GridItem>
                <GridItem>
                  <Text variant="h60} marginBottom="20px" sx={{fontFamily: "PlusJakartaSansBoldr",}}>
                    Flood Info
                  </Text>
                  <ListTable tableData={listingFloodInfoTableData} />
                </GridItem>
              </Grid>
              <Grid
                container
                spacing={2}
                alignItems="flex-start"
                marginTop="20px"
              >
                <GridItem>
                  <Text variant="h60} marginBottom="20px" sx={{fontFamily: "PlusJakartaSansBoldr",}}>
                    More Info
                  </Text>
                  <ListTable tableData={listingMoreInfoTableData} />
                </GridItem>
                <GridItem>
                  <Text variant="h60} marginBottom="20px" sx={{fontFamily: "PlusJakartaSansBoldr",}}>
                    Defects
                  </Text>
                  <ListTable tableData={listingDefectsTableData} />
                </GridItem>
                <GridItem>
                  <Text variant="h60} marginBottom="20px" sx={{fontFamily: "PlusJakartaSansBoldr",}}>
                    Exclusions
                  </Text>
                  <ListTable tableData={listingExclusionsTableData} />
                </GridItem>
                <GridItem>
                  <Text variant="h60} marginBottom="20px" sx={{fontFamily: "PlusJakartaSansBoldr",}}>
                    Seller Info
                  </Text>
                  <ListTable tableData={listingSellerTableData} />
                </GridItem>
              </Grid> */}
            </TabPanel>

            <TabPanel
              value="Events & Offers"
              style={{
                padding: "0px",
                margin: "0px",
                paddingTop: "20px",
              }}
            >
              {" "}
              <GridItem>
                <Text
                  sx={{
                    fontFamily: "PlusJakartaSans-Regular",
                    fontSize: "14px",
                    fontWeight: "600",
                  }}
                  marginBottom="8px"
                >
                  Events
                </Text>
                <Table setCheck={setCheck} tableData={listingEventsTableData} />
              </GridItem>
              <GridItem marginTop="20px">
                <Text
                  sx={{
                    fontFamily: "PlusJakartaSans-Regular",
                    fontSize: "14px",
                    fontWeight: "600",
                  }}
                  marginBottom="8px"
                >
                  Offers
                </Text>
                <Table setCheck={setCheck} tableData={listingOffersTableData} />
              </GridItem>
            </TabPanel>
            <TabPanel
              value="Documents"
              style={{
                padding: "0px",
                margin: "0px",
                paddingTop: "20px",
              }}
            >
              {" "}
              <ManageListingsInspectionDocs
                selectedListing={selectedListing}
                reloadListing={() => fetchListingDetails(selectedListing)}
              />
            </TabPanel>
            <TabPanel
              value="Photos"
              style={{
                padding: "0px",
                margin: "0px",
                paddingTop: "20px",
              }}
            >
              <div>
                <ManageListingsPhotoSelector
                  listing={listing}
                  reloadListing={() => fetchListingDetails(selectedListing)}
                />
              </div>
            </TabPanel>

            <TabPanel
              value="Walkthrough"
              style={{
                padding: "0px",
                margin: "0px",
                paddingTop: "20px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  minHeight: "70vh",
                }}
              >
                <Box>
                  <Text
                    sx={{
                      fontFamily: "PlusJakartaSans-Regular",
                      fontSize: "24px",
                      fontWeight: "600",
                    }}
                    marginBottom="20px"
                  >
                    Manage Virtual Walkthrough URLs
                  </Text>
                  <Text
                    sx={{
                      fontFamily: "PlusJakartaSans-Regular",
                      fontSize: "16px",
                      fontWeight: "500",
                    }}
                    marginBottom="20px"
                  >
                    Please specify a virtual walkthrough URL in the field below,
                    select a listing to connect it to and press confirm. You may
                    view a currently selected listing's virtual walkthrough by
                    clicking the "View current walkthrough" button. Note that
                    only listings awaiting approval are displayed in the table
                    below.
                  </Text>
                  <Text
                    sx={{
                      fontFamily: "PlusJakartaSans-Regular",
                      fontSize: "12px",
                      fontWeight: "400",
                    }}
                    marginBottom="8px"
                  >
                    New virtual walkthrough URL:
                  </Text>
                  <Box
                    sx={{ display: "flex", gap: "10px", alignItems: "center" }}
                  >
                    <input
                      // placeholder="Listing Price"
                      style={{
                        border: "1px solid #EFEFEF",
                        borderRadius: "40px",
                        outline: "none",
                        padding: "10px",
                        height: "40px",
                        width: "60%",
                      }}
                      value={virtualWalkthroughUrl}
                      onChange={(e) => setVirtualWalkthroughUrl(e.target.value)}
                    />
                    <Button
                      style={{
                        backgroundColor: "#FFE500",
                        borderRadius: "40px",
                        color: "black",
                        height: "40px",
                        fontSize: "12px",
                        fontWeight: "600",
                        width: "15%",
                        textTransform: "none",
                      }}
                      // fullWidth
                      onClick={handleConfirm}
                    >
                      Confirm
                    </Button>
                    <Button
                      style={{
                        backgroundColor: "transparent",
                        borderRadius: "40px",
                        border: "1px solid #EFEFEF",
                        color: "black",
                        height: "40px",
                        fontSize: "12px",
                        fontWeight: "600",
                        width: "25%",
                        textTransform: "none",
                        whiteSpace: "nowrap",
                      }}
                      onClick={() => handleViewWalkthrough()}
                    >
                      View Walkthrough
                    </Button>
                  </Box>
                </Box>
                {/* <Box>
                  <Button
                    variant="contained"
                    style={{
                      borderRadius: "30px",
                      whiteSpace: "nowrap",
                      fontSize: "12px",
                      backgroundColor: "#FFE500",
                      color: "black",
                      padding: "10px 0px 10px 0px",
                      textTransform: "none",
                      fontFamily: "PlusJakartaSans-Regular",
                      fontWeight: "700",
                    }}
                    // color=""
                    fullWidth
                    // onClick={() =>
                    //   handleSubmitInspectionDocs({
                    //     listingId: selectedListing,
                    //     documents: [],
                    //   })
                    // }
                  >
                    Save
                  </Button>
                </Box> */}
              </Box>
            </TabPanel>
          </TabContext>
        </Box>
      </Drawer>

      {/* <>
        {selectedListing && (
          <>
            <div style={{ marginBottom: "40px" }}>
              <ManageListingsUpdateForm
                listing={listing as DetailedListing}
                removeListing={async (params: RemoveListingFunctionParams) => {
                  const res = await removeListing(params);
                  api.success({
                    message: `Listing removed`,
                    placement: "bottomRight",
                  });
                  return res;
                }}
                loadingRemoveListing={loadingRemoveListing}
                errorRemoveListing={!!errorRemoveListing}
              />
            </div>
            <Grid container spacing={2} alignItems="flex-start">
              <GridItem>
                <Text variant="h60} marginBottom="20px" sx={{fontFamily: "PlusJakartaSansBoldr",}}>
                  Details
                </Text>
                <ListTable tableData={listingDetailsTableData} />
              </GridItem>
              <GridItem>
                <Text variant="h60} marginBottom="20px" sx={{fontFamily: "PlusJakartaSansBoldr",}}>
                  Features
                </Text>
                <ListTable tableData={listingFeaturesTableData} />
              </GridItem>
              <GridItem>
                <Text variant="h60} marginBottom="20px" sx={{fontFamily: "PlusJakartaSansBoldr",}}>
                  Conditions
                </Text>
                <ListTable tableData={listingConditionsTableData} />
              </GridItem>
              <GridItem>
                <Text variant="h60} marginBottom="20px" sx={{fontFamily: "PlusJakartaSansBoldr",}}>
                  Flood Info
                </Text>
                <ListTable tableData={listingFloodInfoTableData} />
              </GridItem>
            </Grid>
            <Grid
              container
              spacing={2}
              alignItems="flex-start"
              marginTop="20px"
            >
              <GridItem>
                <Text variant="h60} marginBottom="20px" sx={{fontFamily: "PlusJakartaSansBoldr",}}>
                  More Info
                </Text>
                <ListTable tableData={listingMoreInfoTableData} />
              </GridItem>
              <GridItem>
                <Text variant="h60} marginBottom="20px" sx={{fontFamily: "PlusJakartaSansBoldr",}}>
                  Defects
                </Text>
                <ListTable tableData={listingDefectsTableData} />
              </GridItem>
              <GridItem>
                <Text variant="h60} marginBottom="20px" sx={{fontFamily: "PlusJakartaSansBoldr",}}>
                  Exclusions
                </Text>
                <ListTable tableData={listingExclusionsTableData} />
              </GridItem>
              <GridItem>
                <Text variant="h60} marginBottom="20px" sx={{fontFamily: "PlusJakartaSansBoldr",}}>
                  Seller Info
                </Text>
                <ListTable tableData={listingSellerTableData} />
              </GridItem>
            </Grid>

            <GridItem marginTop="20px">
              <Text variant="h60} marginBottom="20px" sx={{fontFamily: "PlusJakartaSansBoldr",}}>
                Events
              </Text>
              <Table tableData={listingEventsTableData} />
            </GridItem>

            <GridItem marginTop="20px">
              <Text variant="h60} marginBottom="20px" sx={{fontFamily: "PlusJakartaSansBoldr",}}>
                Offers
              </Text>
              <Table tableData={listingOffersTableData} />
            </GridItem>

            <div style={{ marginTop: "36px" }}>
              <ManageListingsInspectionDocs
                selectedListing={selectedListing}
                reloadListing={() => fetchListingDetails(selectedListing)}
              />
            </div>

            <div style={{ marginTop: "36px" }}>
              <ManageListingsPhotoSelector
                listing={listing}
                reloadListing={() => fetchListingDetails(selectedListing)}
              />
            </div>
          </>
        )}
      </> */}

      <Modal
        open={openChooseColumn}
        onClose={handleCloseChooseColumn}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{
          padding: "30px",
          backgroundColor: "transparent",
          backdropFilter: "blur(2px)",
        }}
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Text
              sx={{
                color: "black",
                fontFamily: "PlusJakartaSans-Regular",
                fontWeight: "600",
                fontSize: "24px",
              }}
            >
              Choose Columns
            </Text>
            {/* <Typography>
              <h4 style={{ fontFamily: "PlusJakartaSans-Bold" }}></h4>
            </Typography> */}
            <img
              src={closeIcon}
              alt="Close Icon"
              style={{ cursor: "pointer" }}
              onClick={handleCloseChooseColumn}
            />
          </Box>
          <Typography id="modal-modal-description">
            <div
              style={{
                border: "1px solid #0000000D",
                borderRadius: "10px",
                display: "flex",
                flexDirection: "column",
                marginTop: "20px",

                // width,
              }}
            >
              <div
                style={{
                  display: "flex",
                  overflow: "hidden",
                  borderTopRightRadius: "10px",
                  borderTopLeftRadius: "10px",
                }}
              >
                <div
                  style={{
                    borderRight: "1px solid #0000000D",
                    backgroundColor: "#FFE500",
                    width: "50%",
                    padding: "5px 15px",
                  }}
                >
                  Available
                </div>
                <div
                  style={{
                    width: "50%",
                    padding: "5px 15px",
                    backgroundColor: "#FFE500",
                  }}
                >
                  Selected
                </div>
              </div>
              <div
                style={{
                  display: "flex",
                  overflow: "hidden",
                  borderBottom: "1px solid #0000000D",
                  fontSize: "12px",
                  height: "400px",
                  overflowY: "auto",
                }}
              >
                <div style={{ width: "50%" }}>
                  {availableColumn?.map((h) => (
                    <div
                      style={{
                        borderRight: "1px solid #0000000D",
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        borderBottom: "1px solid #0000000D",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{
                          fontFamily: "PlusJakartaSans-Regular",
                          fontWeight: "400",
                        }}
                        className="choosecolumns-div"
                      >
                        {h.title}
                      </div>
                      <div className="choosecolumns-img">
                        <img
                          src={Rightsortingicon}
                          onClick={() => handleSelectColumn(h)}
                        />
                      </div>
                    </div>
                  ))}
                </div>

                <div style={{ width: "50%" }}>
                  {selectedColumn.map((h) => (
                    <div
                      className="choosecolumns-div"
                      style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "space-between",
                        fontFamily: "PlusJakartaSans-Regular",
                        fontWeight: "400",
                        // alignItems: "center",
                      }}
                    >
                      <div
                        className=""
                        style={{ display: "flex", gap: "10px" }}
                      >
                        <div>
                          <img
                            src={Rightsortingicon}
                            style={{ rotate: "180deg" }}
                            onClick={() => handleDeselectColumn(h)}
                          />
                        </div>
                        <div> {h.title}</div>
                      </div>
                      <div className="">
                        <img src={straightIcon} />
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <Button
              // variant="outlined"
              style={{
                backgroundColor:
                  selectedColumn.length === 0 ? "#DDDDDD" : "#FFE500",
                textTransform: "none",
                borderRadius: "30px",
                color: "black",
                // border: "1px solid #DDDDDD",
                padding: "10px 20px 10px 20px",
                height: "35px",
                fontFamily: "PlusJakartaSans-Bold",
                marginTop: "20px",
                fontSize: "12px",
              }}
              disabled={selectedColumn.length === 0}
              onClick={handleSave}
            >
              Save
            </Button>
          </Typography>
        </Box>
      </Modal>
    </ViewWrapper>
  );
};
