import { Box, Grid, Stack } from "@mui/material";
import React, { FormEvent, useEffect, useState } from "react";

import { Button, InputField } from "components/common";
import { useGetAppPrices, useSetAppPrices } from "hooks";
import { AppPrices, AppPriceType } from "types/entities";
import { ViewWrapper } from "components/wrappers";
import { notification } from "antd";
type PricesForm = {
  [key in AppPriceType]?: { id: string; price: string };
};

export const AppSettings: React.FC = () => {
  const { appPrices, loading } = useGetAppPrices();
  const { loading: loadingSetAppPrices, setAppPrices } = useSetAppPrices();
  const [api, contextHolder] = notification.useNotification();

  const [prices, setPrices] = useState<PricesForm>();
  const [initialPriceSet, setInitialPriceSet] = useState<boolean>(false);

  useEffect(() => {
    if (!initialPriceSet && !loading) {
      // appPrices prices need to be stringified
      const alteredAppPrices = { ...appPrices } as any;
      // @ts-ignore
      Object.keys(alteredAppPrices).forEach(
        (key) =>
          (alteredAppPrices[key].price =
            alteredAppPrices[key]?.price?.toString())
      );
      setPrices(appPrices as PricesForm);
      setInitialPriceSet(true);
    }
  }, [initialPriceSet, loading, appPrices]);

  const handleSubmitPriceChange = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!prices) return;

    // Convert string prices to floats & submit
    const newPrices = { ...prices } as any;
    try {
      // @ts-ignore
      Object.keys(newPrices).forEach(
        (key) =>
          (newPrices[key].price = parseFloat(newPrices[key].price).toFixed(2))
      );
    } catch (error) {
      console.warn(
        "Invalid value passed for price in price input. Only digit characters are allowed."
      );
    }
    setAppPrices(newPrices as AppPrices);
    api.success({
      message: `Successfully changed prices`,
      placement: "bottomRight",
    });
  };

  const handlePriceChange = (type: AppPriceType, value: string) => {
    if (prices === undefined) return;

    try {
      // Make sure commas are replaced with periods
      let validatedValue = value.replace(",", ".");
      // Allow up to 2 digits after decimal
      const [leftPart, rightPart = "", ...rest] = validatedValue.split(".");
      if (rest.length !== 0) return;
      if (rightPart) {
        validatedValue = leftPart + "." + rightPart.slice(0, 2);
      }
      setPrices({
        ...prices,
        [type]: {
          ...prices[type],
          price: validatedValue,
        },
      });
    } catch (error) {
      console.warn(
        "Invalid value passed for price in price input. Only digit characters are allowed."
      );
    }
  };

  const priceTypeToLabel = (type: AppPriceType): string => {
    switch (type) {
      case AppPriceType.INSPECTION:
        return "Inspection";
      case AppPriceType.LISTING_PAYMENT:
        return "Listing Payment";
      case AppPriceType.PHOTO_SHOOT:
        return "Photo Shoot";
      case AppPriceType.REPORTS:
        return "Reports";

      default:
        console.warn("Unknown price type");
        return "";
    }
  };

  if (loading || prices === undefined) return null;

  return (
    <ViewWrapper title="Set prices">
      {contextHolder}
      <form onSubmit={handleSubmitPriceChange}>
        <Box
          sx={{
            width: "100%",
          }}
        >
          <Grid container spacing={2}>
            {(Object.keys(prices) as AppPriceType[]).map((key, i) => {
              const price = prices[key];
              if (price === undefined) return null;
              return (
                <Grid item xs={6} key={i}>
                  <InputField
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        // Styling the border
                        "& fieldset": {
                          borderColor: "#E0E0E0",
                        },
                        // On hover
                        "&:hover fieldset": {
                          borderColor: "#D6D6D6",
                        },
                        // On focus
                        "&.Mui-focused fieldset": {
                          borderColor: "#CFCFCF",
                        },
                      },
                      "& .MuiInputBase-input": {
                        fontFamily: "PlusJakartaSans-Regular",
                        fontSize: "12px",
                        // fontWeight: "600",
                      },
                    }}
                    label={priceTypeToLabel(key)}
                    variant="outlined"
                    fullWidth
                    required
                    value={price.price}
                    onChange={(value) => {
                      handlePriceChange(key, value);
                    }}
                  />
                </Grid>
              );
            })}
            <Button
              type="submit"
              style={{
                backgroundColor: "#FFE500",
                fontWeight: "700",
                borderRadius: "30px",
                color: "black",
                height: "40px",
                width: "100px",
                textTransform: "none",
                margin: "20px",
              }}
              disabled={loadingSetAppPrices}
            >
              Update
            </Button>
          </Grid>
        </Box>
      </form>
    </ViewWrapper>
  );
};
