import {
  BaseTextFieldProps,
  FormControl,
  InputLabel,
  TextField,
} from "@mui/material";
import React from "react";
import Box from "@mui/material/Box";

interface OwnProps extends BaseTextFieldProps {
  onChange: (value: string) => void;
}

type TInputField = OwnProps;

export const InputField: React.FC<TInputField> = ({ onChange, ...props }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        width: "100%",
      }}
    >
      <InputLabel
        htmlFor="first-name"
        sx={{
          color: "black",
          fontFamily: "PlusJakartaSans-Regular",
          fontSize: "14px",
          fontWeight: "600",
          // marginTop: "-20px",
        }}
      >
        {props?.label}
      </InputLabel>
      <FormControl>
        <TextField
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            onChange(e.target.value)
          }
          {...props}
          label={null}
          InputProps={{
            style: {
              borderRadius: "30px",
              height: "35px",
              fontSize: "15px",
              fontFamily: "PlusJakartaSans-Regular",
            },
          }}
          // sx={{ marginRight: "10px" }}
        />
      </FormControl>
    </Box>

    // <TextField
    //     InputProps={{
    //         style: {
    //             borderRadius: "30px",
    //             height: "35px",
    //             fontSize: "15px",
    //             fontFamily: "PlusJakartaSans-Regular",
    //         },
    //     }}
    //   onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
    //     onChange(e.target.value)
    //   }
    //   {...props}
    // />
  );
};
